//import stuff
import React, { Component } from 'react';
import { Link } from "gatsby"
//import Img from "gatsby-image/withIEPolyfill"
import { getBiggestDimension, unorphan, formatDate } from './utilities';
import LazyImage from "../components/lazyimage"


class FeaturedPublications extends Component {
    constructor(props) {
        super(props);
        this.state = {

            titles: this.props.titles,
            bigSide: getBiggestDimension(this.props.titles),
            refs: this.makeRefs()
        };

    }


    makeRefs() {
        const items = this.props.titles
        let refs = []

        for (const item of items) {
            refs[item.node.id] = React.createRef()
        }

        //()
        return refs

    }

    // move this to utils
    // accepts list of books w/ acf dimensions fields




    render() {




        return (
            // wrapper div of component
            <div className="books featured_titles">



                <div className="boks ">




                    {this.state.titles.map(({ node }) => (
                        <div
                            className={"book featured"}
                            key={node.id}
                            ref={this.state.refs[node.id]}
                        ><Link to={"/titles/" + node.slug} className="booklink">
                                <div className="coverWrap">                                <div className="cover" style={{
                                    display: "block",
                                    width: (90 * (node.acf.dimensions.width / this.state.bigSide)) + "%",
                                    height: (90 * (node.acf.dimensions.height / this.state.bigSide)) + "%"

                                }}>
                                      <LazyImage image={node.acf.cover_image} alt="" sizes="(min-width: 800px) 50vw, 100vw" ></LazyImage>

                                </div>
                                </div>
                                <div className="book_title">
                                    <h4 dangerouslySetInnerHTML={{ __html: unorphan(node.title) }} />

                                </div>


                            </Link>
                        </div>
                    ))}
                </div>

            </div>
        );
    }
}
// export the class so you can call it elsewhere
export default FeaturedPublications;