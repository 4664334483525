export const unorphan = function(str){
    let words = str.trim().split("/").join("/<wbr>").split(" ")

    const lastWord = words.pop();

    if (lastWord.length > 7) return str

    const newString = words.join(' ') + "&nbsp;" + lastWord;

    return newString
}
export const getBiggestDimension = function(items) {
    //const items = this.props.titles
    let biggest = 0

    if (!items) return 0

    for (const item of items) {
        // console.log(item.node.acf)
        biggest = Math.max(biggest, item.node.acf.dimensions.width)
        biggest = Math.max(biggest, item.node.acf.dimensions.height)

    }
    return biggest

}

export const formatDate = function(dateString){
    if (!dateString || dateString.trim().length < 8 ) return ""

    const yyyy = dateString.substring(0,4)
    const mm = dateString.substring(4,6)
    const dd = dateString.substring(6,8)


    const theDate = new Date(yyyy + "-" + mm + "-" + dd)

    const newDateString = theDate.toLocaleDateString("en-US", {
            month: 'long',
            year : 'numeric'
    })

    return newDateString;
}

export const isMobile = function (width) {
   return width > 900
}


export const cdnify = input => {
    const oldPath = process.env.WP_MEDIA_URL
    const newPath = process.env.CDN_MEDIA_URL

    oldPath.replace('http://','https://') // some old urls aren't https.
    return input.replace(oldPath,newPath)   
}