import React  from "react"
import { Link } from "gatsby"
import { unorphan } from "../components/utilities"
import LazyImage from "./lazyimage";

const NewsItem = ({ node, list, showImage }) => (
    <div className={"news_item " + (list ? " list" : "")}>
        <div>
            <div>
                <Link to={"/news/" + node.slug}>

                    { node.acf.news_image && showImage && <div className="news_img">
                        <LazyImage image={node.acf.news_image} alt=""  sizes="(min-width: 600px) 50vw, (min-width: 1000px) 25vw, 100vw" ></LazyImage></div>}
                   
                    <span className="date">{node.date}</span>
                    <span dangerouslySetInnerHTML={{ __html: unorphan(node.title) }} />


                </Link>
            </div>
        </div>
    </div>)

export default NewsItem