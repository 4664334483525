import React from "react"
import LazyLoad from "react-lazyload"
import ProgressiveImage from "react-progressive-image"
import { cdnify } from "../components/utilities"
const LazyImage = ({ image, alt, sizes }) => {
    let sizeNames = [
        'tiny',
        'thumbnail',
        'medium',
        'medium_large',
        'large',
        'full'
    ]
    let imgList = []

    let altText = "Image"
    let placeholder = ""



    if (image) {
        //console.log(image)
        // oops we can probably just make this look for all the keys



        sizeNames.forEach(function (i) {
            if (image.media_details.sizes[i]) {
                //console.log(image.media_details.sizes[i])
                const sized = image.media_details.sizes[i]
                // set placeholder


                const cdnSrc = cdnify(sized.source_url)
                if (i === "tiny") placeholder = cdnSrc

                const srcSetString = cdnSrc + " " + sized.width + "w"
                imgList.push(srcSetString)
            }
        })

        if (!imgList.length && image.source_url) imgList.push(cdnify(image.source_url))

        altText = alt ? alt : image.alt_text
    }


    const srcSet = imgList.join(", ")
    return <React.Fragment><LazyLoad>
        {image && <ProgressiveImage
            src={placeholder}
            srcSetData={{
                srcSet: srcSet,
                sizes: sizes
            }}
            placeholder={placeholder}
        >
            {(src, loading, srcSetData) => (
                <img
                    style={{ opacity: loading ? 0.5 : 1, transition: 'all .5s' }}
                    src={src}
                    srcSet={srcSetData.srcSet}
                    sizes={srcSetData.sizes}
                    alt={altText}
                    width={image.media_details.width}
                    height={image.media_details.height}
                />

            )}
        </ProgressiveImage>}
    </LazyLoad>
        {(src, srcSetData) => (
            <noscript>
                <img
                   
                    src={src}
                    srcSet={srcSetData.srcSet}
                    sizes={srcSetData.sizes}
                    alt={altText}
                    width={image.media_details.width}
                    height={image.media_details.height}
                />
            </noscript>
        )}
    </React.Fragment>
}




export default LazyImage