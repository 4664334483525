import React, { Component } from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Sticky from 'react-stickynode';
import { isMobile } from '../components/utilities.js'
import FeaturedPublications from "../components/featuredpublications.js"
import NewsItem from "../components/newsitem.js";


class PersonPageTemplate extends Component {
  constructor(props) {
    super(props);
    this.currentPage = this.props.data.wordpressWpPeople

    const currentPageId = this.currentPage.wordpress_id



    const relatedTitles = this.props.data.allWordpressWpTitles.edges.filter(edge => edge.node.people.indexOf(+currentPageId) > -1)
    this.relatedTitles = relatedTitles


    const relatedPosts = []
    this.props.data.allWordpressPost.edges.filter(edge => edge.node.people.indexOf(+currentPageId) > -1)
    this.relatedPosts = relatedPosts

    this.state = { width: 0, height: 0 }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }


  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }



  render() {



    const currentPage = this.currentPage


    return (
      <div title={currentPage.name} >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Gregory R. Miller & Co. – " + currentPage.name}</title>
          <link
            rel="canonical"
            href={"https://grmandco.com/people/" + currentPage.slug}
          />
        </Helmet>
        <h1 className="page_title" dangerouslySetInnerHTML={{ __html: currentPage.name }} />
        <div className="description" dangerouslySetInnerHTML={{ __html: currentPage.description }} />

        <div className="aux_content book_details">
          <div className="pusher">

          </div>
        </div>

        <div className="main_content" id="main_content">


          <FeaturedPublications titles={this.relatedTitles}></FeaturedPublications>


        </div>
      </div>
    )
  }
}

export default PersonPageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpPeople(id: { eq: $id }) {
      name
      description
      slug
      wordpress_id
      acf {
        bio
      }
    }
    allWordpressWpTitles {
      edges {
        node {
          id
          people
          title
          slug
          acf {
            publish_date
            isbn
            dap_link
            isbn
            dap_link
            publish_date
            dimensions {
              width
              height
            }
            cover_image {
              id
              media_details {
                width
                height
                file
                sizes {
                  tiny {
                    file
                    width
                    height
                    mime_type
                    source_url
                  }    
                  medium {
                    width
                    height
                    source_url
                  }
                  large {
                    width
                    height
                    source_url
                  }
                  full {
                    width
                    height
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }
    allWordpressPost {
      edges {
        node {
          id
          people
          title
          slug
          acf {
            news_image {
              id
              media_details {
                width
                height
                file
                sizes {
                  tiny {
                    width
                    height
                    source_url
                  }
                  medium {
                    width
                    height
                    source_url
                  }
                  large {
                    width
                    height
                    source_url
                  }
                  full {
                    width
                    height
                    source_url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
